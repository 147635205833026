<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div class="d-flex align-items-center row m-0">
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-bottom: 20px;">
        Select which report you would like to download into a CSV file.
      </div>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding">
      <mdb-btn
        v-show="!spinningPlayerDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="playerTransactionsDump()"
      >
        PLAYER TRANSACTIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningPlayerDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningDrawsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="drawsDump()"
      >
        DRAWS
      </mdb-btn>
      <mdb-btn
        v-show="spinningDrawsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningTicketDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="ticketTransactionsDump()"
      >
        TICKET TRANSACTIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningTicketDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningRecurDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="recurringPaymentsDump()"
      >
        RECURRING PAYMENTS
      </mdb-btn>
      <mdb-btn
        v-show="spinningRecurDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningClubsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="clubDump()"
      >
        CLUBS
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningClubRevenuesDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="clubRevenuesDump()"
      >
        RECONCILIATION
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubRevenuesDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningUserDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="signUpUsersDump()"
      >
        REGISTERED USERS
      </mdb-btn>
      <mdb-btn
        v-show="spinningUserDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>

    <!--
      <mdb-btn
        v-show="!spinningFakeDrawDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="fakedraw();"
      >
        SIMULATE DRAW
      </mdb-btn>
      <mdb-btn
        v-show="spinningFakeDrawDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <div class="d-flex align-items-center row m-0">
        <div style="text-align: left;font-size: 0.9rem;width:100%;margin-top:20px;margin-bottom: 20px;">
          X.COM TESTING FUNCTIONS
        </div>
      </div>
      <mdb-btn
        v-show="!spinningExImage"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="weeklyResultsTweet();"
      >
        X.COM POST (results)
      </mdb-btn>
      <mdb-btn
        v-show="spinningExImage"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <br><br>
      <mdb-btn
        v-show="!spinningExpImage"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="dailyPromotionalTweet();"
      >
      X.COM POST (promo)
      </mdb-btn>
      <mdb-btn
        v-show="spinningExpImage"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <br><br>
      <mdb-btn
        v-show="spinningLb"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <br><br>
      <mdb-btn
        class="btn primary-btn btn-radius m-0"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="recurringpayments_REMOVED();"
      >
        RECURRING PAYMENTS
      </mdb-btn>
    -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: '',
  data () {
    return {
      spinningPlayerDump: false,
      spinningDrawsDump: false,
      spinningTicketDump: false,
      spinningRecurDump: false,
      spinningClubsDump: false,
      spinningClubRevenuesDump: false,
      spinningUserDump: false,
      spinningFakeDrawDump: false,
      spinningLeaderboardDump: false,
      spinningFakeHeroImage: false,
      spinningExImage: false,
      spinningExpImage: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    
    //no longer required or redundant test code

    //recurringpayments () {
    //  baseService.recurringpayments(this.userData.access_token).then(() => { 
    //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'PROCESS', errorMessage: 'Recurring payments task processing...' })
    //  })
    //},
    //weeklyResultsTweet () {
    //  this.spinningExImage = true
    //  baseService.weeklyResultsTweet(this.userData.access_token).then(() => { 
    //    this.spinningExImage = false
    //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'SUCCESS', errorMessage: 'Successfully posted to X.COM!' })
    //  }).catch((err) => {
    //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'ERROR', errorMessage: 'Error posting to X.COM (possible duplicate tweet)!' })
    //    this.spinningExImage = false
    //  })
    //},
    //dailyPromotionalTweet () {
    //  this.spinningExpImage = true
    //  baseService.dailyPromotionalTweet(this.userData.access_token).then(() => { 
    //    this.spinningExpImage = false
    //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'SUCCESS', errorMessage: 'Successfully posted to X.COM!' })
    //  }).catch((err) => {
      //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'ERROR', errorMessage: 'Error posting to X.COM (possible duplicate tweet)!' })
    //    this.spinningExpImage = false
    //  })
    //},
    //generateHeroImage () {
    //  this.spinningFakeHeroImage = true
    //  baseService.generateHeroImage(this.userData.access_token).then(() => { 
    //    this.spinningFakeHeroImage = false
    //  }).catch((err) => {
    //    this.spinningFakeHeroImage = false
    //  })
    //},
    //fakedraw () {
    //  this.spinningFakeDrawDump = true
    //  baseService.fakedraw(this.userData.access_token).then((res) => { 
    //    this.spinningFakeDrawDump = false
    //    if (res.data) {
    //      window.location.assign(res.data)
    //    }
    //  }).catch((err) => {
        //    this.spinningFakeDrawDump = false
    //  })
    //},

    playerTransactionsDump () {
      this.spinningPlayerDump = true
      baseService.playerTransactionsDump(this.userData.access_token).then((res) => {
        this.spinningPlayerDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningPlayerDump = false
      })
    },
    drawsDump () {
      this.spinningDrawsDump = true
      baseService.drawsDump(this.userData.access_token).then((res) => {
        this.spinningDrawsDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningDrawsDump = false
      })
    },
    ticketTransactionsDump () {
      this.spinningTicketDump = true
      baseService.ticketTransactionsDump(this.userData.access_token).then((res) => {
        this.spinningTicketDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningTicketDump = false
      })
    },
    recurringPaymentsDump () {
      this.spinningRecurDump = true
      baseService.recurringPaymentsDump(this.userData.access_token).then((res) => {
        this.spinningRecurDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningRecurDump = false
      })
    },
    clubDump () {
      this.spinningClubsDump = true
      baseService.clubDump(this.userData.access_token).then((res) => {
        this.spinningClubsDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningClubsDump = false
      })
    },
    clubRevenuesDump () {
      this.spinningClubRevenuesDump = true
      baseService.clubRevenuesDump(this.userData.access_token).then((res) => {
        this.spinningClubRevenuesDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningClubRevenuesDump = false
      })
    },
    signUpUsersDump () {
      this.spinningUserDump = true
      baseService.signUpUsersDump(this.userData.access_token).then((res) => {
        this.spinningUserDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningUserDump = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .mobWidth {
    width:100% !important;
 }
 .noRightPadding {
    margin-right: 0px !important;
 }
} 
</style>
