<template>
  <div style="height: 100%;width: 100%;margin-top:20px;">
    <div style="margin-bottom:20px;">
      RECONCILIATION (club revenue) for {{ clubName }}
    </div>
    <BTable
      id="revenues-table"
      class="text-left revenuesTable"
      head-variant="dark"
      :items="revenues"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      responsive
      :striped="true"
      hover
      small
      sort-icon-left
      auto-width
    >
      <template v-slot:cell(month)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          {{ new Date(data.item.Month).toLocaleString('default', { year: 'numeric', month: 'short' }) }}
        </div>
      </template>
      <template v-slot:cell(playercount)="data">
        <div
          v-if="windowWidth < 577"
          class="adminTableRow"
          style="margin-top:12px;"
        >
          {{ data.item.PlayerCount }} ({{ data.item.HeroCount }})
        </div>
        <div
          v-else
          class="adminTableRow"
          style="margin-top:12px;"
        >
          {{ data.item.PlayerCount }}
        </div>
      </template>
      <template v-if="windowWidth > 576" v-slot:cell(herocount)="data">
        <div class="adminTableRow" style="margin-top:12px;">
          {{ data.item.HeroCount }}
        </div>
      </template>
      <template v-slot:cell(amount)="data">
        <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
          £{{ data.item.Amount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(ispaid)="data">
        <div class="adminTableRow" style="margin-top:12px;">
          <!-- PAID -->
          <span v-if="data.item.IsPaid" class="payoutAdminTableStatus paid">
            <i class="fa fa-check" />
            PAID
          </span>
          <!-- DUE -->
          <div
            v-else-if="paymentIsDue(data.item.Month)"
            v-show="data.item.Amount > 0"
            class="payoutAdminTableStatus due"
            :class="{'d-flex align-items-center' : data.item.Amount > 0 }"
          >
            <mdb-btn
              v-if="paymentIsDue(data.item.Month) && windowWidth > 576"
              style="font-size: 0.8rem !important; height: 30px !important;margin-left: -10px !important;margin-top: -5px !important;"
              class="btn primary-btn btn-radius btn-sm btn-md p-1 m-0 d-flex align-items-center justify-content-center ml-2"
              size="xs"
              @click="setClubAsPaid(data.item.Month)"
            >
              SET AS PAID
            </mdb-btn>
            <div
              v-else
              class="primary-colour"
              style="font-size: 0.8rem !important;"
              @click="setClubAsPaid(data.item.Month)"
            >
              SET AS PAID?
            </div>
          </div>
          <!-- ONGOING - NOT PAID BUT DATE OF PAYMENT IS IN THE FUTURE -->
          <span
            v-else
            class="payoutAdminTableStatus ongoing"
          >
            ONGOING
          </span>
          <!-- DUE -->
        </div>
      </template>
    </BTable>
    <mdb-btn
      style="width:120px;margin:0 !important;font-size:0.8rem !important;"
      class="btn primary-btn btn-radius btn-sm btn-md"
      @click="goBack()"
    >
      BACK
    </mdb-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import { PAYMENT_IS_DUE } from '@/common/utils'

export default {
  name: 'ClubRevenues',
  props: {
    clubId: Number,
    clubName: String,
    clubLetter: String
  },
  data () {
    return {
      currentPage: 1,
      revenues: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'Month', label: 'Payment Month' },
          { key: 'PlayerCount', label: 'Players', sortable: true },
          { key: 'HeroCount', label: 'Hero Count' },
          { key: 'Amount', label: 'Amount' },
          { key: 'IsPaid', label: 'Status' }
        ]
      } else {
        return [
          { key: 'Month', label: 'Month' },
          { key: 'PlayerCount', label: 'Ply', sortable: true },
          { key: 'Amount', label: '£' },
          { key: 'IsPaid', label: 'Status' }
        ]
      }
    },
    perPage () {
      return 12
    },
    rows () {
      return 12
    }
  },
  mounted () {
    this.getClubRevenuesByClubId ()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async goBack () {
      try {
        const res = await adminService.getClubsByLetters(this.clubLetter, this.userData.access_token)
        this.clubs = res.data !== null ? res.data : []
        this.loaded = true
        this.$parent.page = 'ClubsTable'
      } catch (err) {
        this.loaded = false
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getClubRevenuesByClubId  () {
      try {
        const res = await adminService.getClubRevenuesByClubId(this.clubId, this.userData.access_token)
        this.revenues = res.data.filter(revenue => {
          return revenue.Forecast === false
        })
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    },
    paymentIsDue (paymentDate) {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const checkDate = new Date(paymentDate)
      const paymentMonth = checkDate.getMonth()
      const paymentYear = checkDate.getFullYear()
      if (paymentMonth === currentMonth && paymentYear === currentYear) {
        return false
      } else {
        return PAYMENT_IS_DUE(paymentDate)
      }
    },
    async setClubAsPaid (paymentDate) {
      try {
        var date = new Date(paymentDate);
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        var formattedDate = year + "-" + month + "-" + day;

        await adminService.setClubAsPaid(formattedDate, this.clubId, this.userData.access_token)
        this.getClubRevenuesByClubId()
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    }
  }
}
</script>

<style scoped>
.payoutAdminTableStatus.paid {
  color: var(--pr-color);
}
.payoutAdminTableStatus.due {
  color: red;
}
.revenuesTable * {
  font-size: 0.85rem !important;
}
</style>
