<template>
  <div
    v-if="allowAccess"
    style="max-width:100%; width: 100%;height:100%;overflow-x: none;"
    class="d-flex flex-column py-3"
  >
    <LeftTopPanelTitle
      :loading="loading"
      text="Admin"
      icon="cog"
    />
    <ul class="nav nav-tabs d-flex justify-content-around my-3 font-weight-bold">
      <li
        v-for="(page, i) in pages"
        :key="i"
        class="nav-item text-center text-nowrap flex-grow-1 smallerFont"
      >
        <a
          :title="page.pageName"
          class="nav-link primary-colour"
          :class="currentPage === page.pageName ? 'active' : 'white-text font-weight-light'"
          @click="currentPage = page.pageName"
        >
          <mdb-icon :icon="page.icon" style="margin-right:5px;" />
          <span
            :class="{'d-none' : currentPage != page.pageName}"
            class="d-sm-inline mr-2"
          >{{ page.pageName }}</span>
        </a>
      </li>
    </ul>
    <div v-show="!loading" class="adminSubsection">
      <Clubs
        v-if="currentPage === 'Clubs'"
        @loading="setLoading($event)"
      />
      <Players
        v-if="currentPage === 'Players'"
        @loading="setLoading($event)"
      />
      <RevenueBreakdown
        v-if="currentPage === 'Revenue'"
        @loading="setLoading($event)"
      />
      <Stats
        v-if="currentPage === 'Stats'"
        @loading="setLoading($event)"
      />
      <Reports
        v-if="currentPage === 'Reports'"
        @loading="setLoading($event)"
      />
      <Leaderboards
        v-if="currentPage === 'Leaderboards'"
        @loading="setLoading($event)"
      />
    </div>
  </div>
</template>

<script>

import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'
import Clubs from './clubs/clubs.vue'
import Reports from './reports.vue'
import Leaderboards from './leaderboards.vue'
import RevenueBreakdown from './revenueBreakdown.vue'
import Players from './players/players.vue'
import Stats from './stats.vue'
import adminService from '@/api-services/admin.service'
import { mapGetters } from 'vuex'

export default {
  name: 'AdminOps',
  components: {
    LeftTopPanelTitle, Clubs, Reports, RevenueBreakdown, Players, Stats, Leaderboards
  },
  data () {
    return {
      currentPage: 'Clubs',
      pages: [
        { pageName: 'Clubs', icon: 'shield-alt' },
        { pageName: 'Players', icon: 'user' },
        { pageName: 'Revenue', icon: 'money-bill-wave' },
        { pageName: 'Stats', icon: 'chart-pie' },
        { pageName: 'Reports', icon: 'file-csv' },
        { pageName: 'Leaderboards', icon: 'table' },
      ],
      loading: false,
      allowAccess: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
  },
  created () {
    this.hasAdminAccess()
    console.log(this.userData)
  },
  methods: {
    async hasAdminAccess () {
      try {
        const res = await adminService.hasAdminAccess(this.userData.access_token)
        console.log(res.data)
        this.allowAccess = true
      } catch (err) {
        this.allowAccess = false
        this.$router.push({ path: '/play', query: { page: 1 } })
      }
    },
    setLoading (boolean) {
      this.loading = boolean
    }
  }
}
</script>

<style>
.adminSubsection {
  overflow: auto;
  width: 100%;
}
.adminSubsectionComponent {
  width: 100%;
}
@media (max-width: 576px) {
  .smallerFont {
    font-size: 10px !important;
}
} 

</style>
