<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div class="d-flex align-items-center row m-0">
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-bottom: 20px;">
        Select which of the two following reports you would like to download into a CSV file.
      </div>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:5px;">
      <mdb-btn
        v-show="!spinningLeaderboardDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="getLeaderboardDump()"
      >
        LEADERBOARD
      </mdb-btn>
      <mdb-btn
        v-show="spinningLeaderboardDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningLeaderboardWinnersDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="getLeaderboardWinnersDump()"
      >
        LEADERBOARD WINNERS
      </mdb-btn>
      <mdb-btn
        v-show="spinningLeaderboardWinnersDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div v-if="userData.uid === 'c16892e8-89fb-4353-a793-3ac84675eed6'" class="d-flex align-items-center row m-0">
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-bottom: 20px;margin-top: 25px;;">
        Select this to reset and re-populate the leaderboard for all clubs.
      </div>
    </div>
    <div v-if="userData.uid === 'c16892e8-89fb-4353-a793-3ac84675eed6'" class="mr-4 mr-md-5 noRightPadding">
      <mdb-btn
        v-show="!spinningLb"
        class="btn secondary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="setLeaderBoard();"
      >
        SET LEADERBOARD
      </mdb-btn>
      <mdb-btn
        v-show="spinningLb"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: '',
  data () {
    return {
      spinningLeaderboardDump: false,
      spinningLb: false,
      spinningLeaderboardWinnersDump: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    setLeaderBoard () {
      this.spinningLb = true
      baseService.setLeaderBoard(this.userData.access_token).then(() => { 
        this.spinningLb = false
      }).catch((err) => {
        console.log(err)
        this.spinningLb = false
      })
    },
    getLeaderboardDump () {
      this.spinningLeaderboardDump = true
      baseService.getLeaderboardDump(this.userData.access_token).then((res) => {
        this.spinningLeaderboardDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningLeaderboardDump = false
      })
    },
    getLeaderboardWinnersDump () {
      this.spinningLeaderboardWinnersDump = true
      baseService.getLeaderboardWinnersDump(this.userData.access_token).then((res) => {
        this.spinningLeaderboardWinnersDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch((err) => {
        console.log(err)
        this.spinningLeaderboardWinnersDump = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .mobWidth {
    width:100% !important;
 }
 .noRightPadding {
    margin-right: 0px !important;
 }
} 
</style>
