<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div
      v-for="(type, i) in dataToShow"
      :key="i" 
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>{{ type.text }}:</span>
      <span
        v-if="type.amount === 'TBC'"
        class="text-white font-weight-bold ml-2"
      >
        {{ type.amount }}
      </span>
      <span
        v-else
        class="text-white font-weight-bold ml-2"
      >
        £{{ type.amount }}
      </span>
    </div>
    *for current year
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'RevenueBreakdown',
  data () {
    return {
      prize: 0.00,
      monthlyPrize: 0.00
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    dataToShow () {
      return [
        { text: 'Total Revenue*', amount: Number((this.prize) * 5).toFixed(2) },
        { text: 'Club Revenue*', amount: Number(((this.prize * 5) / 100) * 50).toFixed(2) },
        { text: 'GRL Revenue*', amount: Number(((this.prize * 5) / 100) * 30).toFixed(2) },
        { text: 'Prize Pot*', amount: Number(((this.prize * 5) / 100) * 20).toFixed(2) },
        { text: 'Active Monthly Draws*', amount: 'TBC' } // Number(this.monthlyPrize).toFixed(2)
      ]
    }
  },
  created () {
    this.privatePrizePot()
  },
  methods: {
    privatePrizePot () {
      baseService.privatePrizePot(this.userData.access_token).then((res) => {
        if (res.data >= 50) {
          const interval = Math.floor(res.data / 50)
          const incrementInterval = setInterval(() => {
            if (this.prize < res.data.TotalPrizePot) {
              this.prize += interval
            } else {
              this.prize = res.data.TotalPrizePot
              clearInterval(incrementInterval)
            }
          }, 30)
        } else {
          this.prize = res.data.TotalPrizePot
        }

        this.monthlyPrize = res.data.MonthlyPrizePot
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
