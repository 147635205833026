<template>
  <div>
    <validation-observer>
      <form style="width:100%">
        <div class="md-form">
          <div class="md-form form-sm formMarginTop">
            <validation-provider
              mode="lazy"
              name="Forename"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.fnm"
                type="text"
                auto-complete="ex-fnm"
                label="Forename"
                style="margin-left:33px;max-width:100%;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Surname"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.lnm"
                type="text"
                auto-complete="ex-lnm"
                label="Surname"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Email"
              rules="required"
            >
              <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.une"
                type="email"
                auto-complete="ex-une"
                label="Email"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:-15px;float:left;margin-bottom:-10px;">
            <validation-provider
              mode="lazy"
              name="Date of birth"
              rules="required"
            >
              <i class="fas fa-birthday-cake prefix sm" style="left:0;margin-top:33px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.day"
                type="text"
                auto-complete="ex-day"
                label="DD"
                style="margin-left:33px;margin-right:10px;width:27%;float:left;"
                size="sm"
              />
              <mdb-input
                v-model="existingUser.mth"
                type="text"
                auto-complete="ex-mth"
                label="MM"
                style="margin-right:10px;width:27%;float:left;"
                size="sm"
              />
              <mdb-input
                v-model="existingUser.yer"
                type="text"
                auto-complete="ex-yer"
                label="YYYY"
                style="width:27%;float:left;"
                size="sm"
                class="mobile-date"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:105px;">
            <validation-provider
              mode="lazy"
              name="First line of address"
              rules="required"
            >
              <i class="fas fa-home prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.adr"
                type="text"
                auto-complete="ex-add"
                label="First line of address"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Postcode"
              rules="required"
            >
              <i class="fas fa-map-pin prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.pce"
                type="text"
                auto-complete="ex-pcd"
                label="Postcode"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Telephone"
              rules="required"
            >
              <i class="fas fa-phone prefix sm" style="left:0;margin-top:9px;font-size:1rem;color:gray;" />
              <mdb-input
                v-model="existingUser.tel"
                type="text"
                auto-complete="ex-tel"
                label="Telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div
            class="md-form form-sm"
            style="margin-top:25px;margin-bottom:45px;"
            @click="toggleAgree()"
          >
            <mdb-input
              v-model="agree"
              type="checkbox"
              auto-complete="ex-agr"
              style="margin-left:-20px;"
              size="sm"
            />
            <div style="margin-left: 33px;margin-top: -15px;font-size: small;color: #555;">
              Tick to confirm you are changing these details.
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
    <div style="float: right;padding-bottom: 60px;" class="mr-4 mr-md-5 mobBtn101">
      <mdb-btn
        v-if="updated === false"
        v-show="!buttonClick"
        :disabled="agree === false"
        class="btn primary-btn btn-right btn-radius btn-sm btn-md m-0 mr-2"
        style="width:120px;margin-right:-40px !important;font-size:0.8rem !important;"
        @click="updateDetails(false)"
      >
        UPDATE
      </mdb-btn>
      <mdb-btn
        v-if="updated === false"
        v-show="buttonClick"
        style="width:100px;margin:0 !important;"
        class="mobBtn101 btn primary-btn btn-right btn-radius btn-sm btn-md"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <mdb-btn
        v-if="updated === true"
        style="width:100px;margin:0 !important;"
        class="mobBtn101 btn primary-btn btn-right btn-radius btn-sm btn-md"
        size="sm"
      >
        <mdb-icon icon="fa fa-check" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'AdminPlayerDetails',
  props: {
    email: {
      type: [String],
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      existingUser: {
        fnm: '',
        lnm: '',
        une: '',
        day: '',
        mth: '',
        yer: '',
        adr: '',
        pce: '',
        tel: '',
        pwd: '',
        new: '',
        con: ''
      },
      buttonClick: false,
      agree: false,
      updated: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  mounted () {
    this.getPlayerDetails()
  },
  methods: {
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    updateDetails () {
      adminService.editPlayerDetails(this.existingUser, this.userData.access_token).then((res) => {
        this.existingUser = res.data
        this.agree = false
        this.buttonClick = false
        this.updatedDetails = true
        setTimeout(() => { this.updatedDetails = false }, 3000)
      }).catch((err) => {
        this.buttonClick = false
        console.log(err)
      })
    },
    getPlayerDetails () {
      adminService.adminGetPlayerDetails(this.email, this.userData.access_token).then((res) => {
        this.existingUser = res.data
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
